require('./bootstrap');
require('alpinejs');

$('.toast').toast('show');

/*
----------------------------------------------------------------------
    Modal Delete
----------------------------------------------------------------------
*/
$deleteBtn = null;

$("body").on("click", ".modalDeleteBtn", function(){
    $deleteBtn = "#"+$(this).attr("btn-delete");
    $('#modalDelete').modal('show');
})

$("body").on("click", ".triggerDeleteBtn", function(){
    $( $deleteBtn ).trigger( "click" );
})

/*
----------------------------------------------------------------------
    Modal Restore
----------------------------------------------------------------------
*/
$restoreBtn = null;
$("body").on("click", ".modalRestoreBtn", function(){
    $restoreBtn = "#"+$(this).attr("btn-restore");
    $('#modalRestore').modal('show');
})

$("body").on("click", ".triggerRestoreBtn", function(){
    $( $restoreBtn ).trigger( "click" );
})


/*
----------------------------------------------------------------------
    Role permission select function
----------------------------------------------------------------------
*/

$("body").on("click", ".permission.parent", function () {
    $(this).toggleClass("active");
    getAllPermission();
});

function getAllPermission(){
    var permissionList = "";
    $( ".permission.active" ).each(function( index ) {
        var permission_id = $(this).attr("permission");
        permissionList+=permission_id+",";
    });
    $("#permissions").val(permissionList);
}


/*
----------------------------------------------------------------------
    Get main message for users
----------------------------------------------------------------------
*/
get_main_message();
function get_main_message(){
    if(window.location.href.indexOf("/moj-profil") > -1) 
    {
        $.ajaxSetup({
            headers: {
                'X-CSRF-TOKEN': '{{ csrf_token() }}'
            }
        });
        $.ajax({
            url: "/moj-profil/get_main_message",
            method: 'get',
            success: function(data){
                if(data.message.length>0){
                    $("#main_message").empty().append('<div class="alert alert-'+data.type+'" style="border-radius: 0px;text-align:center;">'+data.message+'</div>');
                }
            }
        });
    }
}


/*
----------------------------------------------------------------------
    Check for notifications
----------------------------------------------------------------------
*/

check_notifications();
function check_notifications(){
    if(window.location.href.indexOf("/core") > -1) 
    {
        $.ajaxSetup({
            headers: {
                'X-CSRF-TOKEN': '{{ csrf_token() }}'
            }
        });
        $.ajax({
            url: "/core/check_notifications",
            method: 'get',
            success: function(data){
               if(data.count>0){
                   $(".message_count").empty().append("<span>"+data.count+"</span>")
               }
            }
        });
    }
}


check_notifications_users();
function check_notifications_users(){
    if(window.location.href.indexOf("/moj-profil") > -1) 
    {
        $.ajaxSetup({
            headers: {
                'X-CSRF-TOKEN': '{{ csrf_token() }}'
            }
        });
        $.ajax({
            url: "/moj-profil/check_notifications_users",
            method: 'get',
            success: function(data){
               if(data.count>0){
                   $(".message_count").empty().append("<span>"+data.count+"</span>")
               }
            }
        });
    }
}